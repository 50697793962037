import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
// import { auth } from './../../utils/firebaseConfig';
import { getAuthData } from "../../utils/auth";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchReferralDetails } from "./../../Redux/reducers/referAndEarn/referralDetailsRequestReducer";

const ReferAndEarn = () => {
  const authData = getAuthData();
  const dispatch = useDispatch();
  const [referralCode, setReferralCode] = useState("");
  const [error, setError] = useState(null);
  const [hide, setHide] = useState(false);
  const [totalEarnings, setTotalEarnings] = useState(0); // New state for total earnings
  const [amount, setAmount] = useState("");
  const [withdrawError, setWithdrawError] = useState(null);

  const [showWithdrawError, setShowWithdrawError] = useState(false); // State to manage withdrawal error visibility

  const handleAmountChange = (event) => {
    const value = event.target.value;
    // Allow only numeric values
    if (/^\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const handleConfirmClick = () => {
    if (parseFloat(amount) < 100) {
      setWithdrawError("Please Enter Minimum Withdrawal Amount 100/-.");
      setShowWithdrawError(true);
      setTimeout(() => setShowWithdrawError(false), 10000); // Hide the message after 10 seconds
      // return;
      try {
        const userId = authData?.auth?.Id;
        const response = axios.post(
          "https://api.iott.co.in/api/payment/WithdrawBonusAmount",
          {
            UserId: userId,
            Amount: parseFloat(amount),
          }
        );

        if (response.status === 200) {
          // Handle success response, e.g., show a success message
          console.log("Withdrawal successful:", response.data);
          setAmount(""); // Clear the amount input
          setHide(false); // Show the referral details table again
        } else {
          throw new Error("Failed to withdraw bonus amount.");
        }
      } catch (error) {
        setWithdrawError(
          "Please Enter Minimum Withdrawal Amount 100/-."
        );
        console.error("Error processing withdrawal:", error);
      }
    }
  };

  const booleanToString = (value) => (value ? "Active" : "In Active");

  const { details } = useSelector(
    (state) => state.rootReducer.referralDetailsRequestReducer
  );
  console.log(details);

  useEffect(() => {
    const fetchReferralCode = async () => {
      try {
        const userId = authData?.auth?.Id;
        const response = await axios.post(
          "https://api.iott.co.in/api/referral/get_referralcode",
          { UserId: userId }
        );

        if (response.status === 200) {
          setReferralCode(response.data.ReferralCode);
          console.log(response); // Assuming 'code' is the key in the response containing the referral code
        } else {
          throw new Error("Failed to fetch referral code.");
        }
      } catch (error) {
        setError("Error fetching referral code. Please try again later.");
        console.error("Error fetching referral code:", error);
      }
    };

    const fetchTotalEarnings = async () => {
      try {
        const userId = authData?.auth?.Id;
        // Assuming the ReferredByUserId is the same as the current user's ID
        const response = await axios.get(
          `https://api.iott.co.in/api/Payment/GetTotalBonusAmountByReferredByUserId?ReferredByUserId=${userId}`
        );

        if (response.status === 200) {
          console.log(response);
          setTotalEarnings(response.data);
        } else {
          throw new Error("Failed to fetch total earnings.");
        }
      } catch (error) {
        setError("Error fetching total earnings. Please try again later.");
        console.error("Error fetching total earnings:", error);
      }
    };
    fetchReferralCode();
    fetchTotalEarnings();
  }, []);

  const checkData = () => {};

  // useEffect(()=>{

  //   const userId = authData?.auth?.Id;
  //   // dispatch(fetchReferralDetails())

  // })

  const handleLevelClick = (level) => {
    const userId = authData?.auth?.Id;
    dispatch(fetchReferralDetails({ userId, level }));
  };

  const handleWithdrawClick = () => {
    // if (totalEarnings >= 100) {
    setHide(true);
    setWithdrawError(""); // Clear any previous error
    // setShowWithdrawError(false); // Hide error message if visible
    // }
    // else {
    //   setWithdrawError("You need a minimum of 100 rupees in earnings to withdraw.");
    //   setShowWithdrawError(true);
    //   setTimeout(() => setShowWithdrawError(false), 3000); // Hide the message after 10 seconds
    // }
  };
  return (
    <>
      <Header />
      <Grid
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "#010113",
          textAlign: "center",
        }}
      >
        <Container
          sx={{
            textAlign: "center",
            width: "60vw", // Match the width of the TableContainer
          }}
        >
          <Typography
            sx={{
              font: "Quicksand",
              fontWeight: "700",
              fontSize: "35px",
              color: "#FFDA78",
              marginBottom: "20px",
            }}
          >
            Refer & Earn
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <TextField
              value={referralCode}
              sx={{
                width: "70vh",
                "& .MuiInputBase-root": {
                  height: 30,
                  bgcolor: "white",
                  color: "black",
                },
              }}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />

            <Box
              sx={{
                display: "flex",
                gap: "30px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "5px",
                  font: "Nunito",
                  fontWeight: "700",
                  fontSize: "12px",
                }}
                onClick={() => navigator.clipboard.writeText(referralCode)}
              >
                Copy Referral code
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "5px",
                  font: "Nunito",
                  fontWeight: "700",
                  fontSize: "12px",
                }}
              >
                Share
              </Button>
            </Box>
          </Box>

          {error && (
            <Typography
              sx={{
                color: "red",
                marginBottom: "20px",
              }}
            >
              {error}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "12px",
            }}
          >
            <Box>
              <Button
                variant="contained"
                sx={{
                  font: "Quicksand",
                  fontWeight: "700",
                  borderRadius: "5px",
                  marginRight: "10px",
                  bgcolor: "white",
                }}
                onClick={() => handleLevelClick(1)}
              >
                Level 1
              </Button>
              <Button
                variant="contained"
                sx={{
                  font: "Quicksand",
                  fontWeight: "700",
                  borderRadius: "5px",
                  marginRight: "10px",
                  bgcolor: "white",
                }}
                onClick={() => handleLevelClick(2)}
              >
                Level 2
              </Button>
              <Button
                variant="contained"
                sx={{
                  font: "Quicksand",
                  fontWeight: "700",
                  borderRadius: "5px",
                  marginRight: "10px",
                  bgcolor: "white",
                }}
                onClick={() => handleLevelClick(3)}
              >
                Level 3
              </Button>
            </Box>
            <Button
              variant="contained"
              sx={{
                font: "Quicksand",
                fontWeight: "700",
                borderRadius: "5px",
                marginLeft: "auto",
              }}
              onClick={handleWithdrawClick}
            >
              Withdraw
            </Button>
          </Box>

          {showWithdrawError && (
            <Typography
              sx={{
                color: "red",
                marginBottom: "20px",
                font: "Quicksand",
                fontWeight: "700",
              }}
            >
              {withdrawError}
            </Typography>
          )}

          {!hide ? (
            <TableContainer
              sx={{
                font: "Quicksand",
                fontWeight: "700",
                width: "100%",
                margin: "auto",
              }}
            >
              <Table
                sx={{
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                <TableHead
                  sx={{
                    bgcolor: "#FFDA78",
                    font: "QuickSand",
                    fontWeight: "700",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        font: "Quicksand",
                        fontWeight: "700",
                      }}
                    >
                      Sno
                    </TableCell>
                    <TableCell
                      sx={{
                        font: "Quicksand",
                        fontWeight: "700",
                      }}
                    >
                      Id
                    </TableCell>
                    <TableCell
                      sx={{
                        font: "Quicksand",
                        fontWeight: "700",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        font: "Quicksand",
                        fontWeight: "700",
                      }}
                    >
                      Earnings
                    </TableCell>
                    <TableCell
                      sx={{
                        font: "Quicksand",
                        fontWeight: "700",
                      }}
                    >
                      Subscription
                    </TableCell>
                  </TableRow>
                </TableHead>
                {details.length ? (
                  details.map((each, index) => (
                    <TableRow key={each.Id}>
                      <TableCell sx={{ color: "#fff" }}>{index + 1}</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {each.UserId}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {each.UserName}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {each.BonusAmount}
                      </TableCell>
                      <TableCell
                        sx={{ color: each.IsPurchased ? "#5fde10" : "red" }}
                      >
                        {booleanToString(each.IsPurchased)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>No records found</TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          ) : (
            <TableContainer
              sx={{
                font: "Quicksand",
                fontWeight: "700",
                width: "100%",
                margin: "auto",
              }}
            >
              <Table
                sx={{
                  textAlign: "center",
                  alignContent: "center",
                  bgcolor: "white",
                  height: "50vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      font: "Quicksand",
                      fontWeight: "700",
                    }}
                  >
                    Enter Amount
                  </Typography>
                  <TextField
                    placeholder="Enter amount"
                    value={amount}
                    onChange={handleAmountChange}
                    sx={{
                      width: "250px",
                      "& .MuiInputBase-root": {
                        height: 30,
                        bgcolor: "#FFDA78",
                        color: "#000000",
                        fontWeight: 600,
                      },
                    }}
                  />
                </Box>
                <Button
                  sx={{
                    mt: 2,
                    borderRadius: "5px",
                    alignSelf: "center",
                  }}
                  variant="contained"
                  onClick={handleConfirmClick}
                >
                  Confirm
                </Button>
                {withdrawError && (
                  <Typography
                    sx={{
                      color: "red",
                      marginTop: "10px",
                      font: "Quicksand",
                      fontWeight: "700",
                    }}
                  >
                    {withdrawError}
                  </Typography>
                )}
                <Typography
                  sx={{
                    color: "green",
                    marginTop: "10vh",
                    font: "Quicksand",
                    fontWeight: "700",
                  }}
                >
                  Amount credited in 4 business days
                </Typography>
              </Table>
            </TableContainer>
          )}

          <Typography
            sx={{
              font: "Quicksand",
              fontWeight: "700",
              color: "#FFDA78",
              marginTop: hide ? "20px" : "12px",
            }}
          >
            Total Earnings: {totalEarnings}
          </Typography>
        </Container>
      </Grid>
      <Footer />
    </>
  );
};
export default ReferAndEarn;
