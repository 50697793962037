
import React, { useState, useEffect } from "react";
import Header from "../Header";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Typography } from "@mui/material";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import Searchbar from "../Searchbar";
import { setMovieState } from "../../Redux/reducers/videos/getVideoByIdReducer";
 
const SuggestedOrSearchMovies = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
 
  const { suggested_movies } = useSelector(
    (state) => state.rootReducer.getSuggestedMoviesReducer
  );
  const { searchResults } = useSelector(
    (state) => state.rootReducer.searchReducer
  );
 
  const moviesToDisplay = searchResults.length > 0 ? searchResults : suggested_movies;
 
  const headingText = searchResults.length > 0 ? "Search Results" : "Suggested Movies For you";
 
  const onClickMvWatchBtn = (each) => {
    const formattedMovieName = each?.MovieName.replace(/\s+/g, "_");
 
    dispatch(setMovieState(each));
    navigate(`/details/${formattedMovieName}`);
  };
 
  useEffect(() => {
    // Set the message based on the content of moviesToDisplay
    if (moviesToDisplay.length === 0) {
      setMessage("No Movies yet, visit again..!");
    } else if (moviesToDisplay[0]?.message) {
      setMessage(moviesToDisplay[0]?.message);
    } else {
      setMessage("");
    }
  }, [moviesToDisplay]);
 
  return (
    <>
      <Header />
      <Grid
        container
        bgcolor={"#000"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        minHeight={500}
      >
        <Grid
          container
          xs={12}
          display={{ xs: "flex", md: "none" }}
          justifyContent={"center"}
          alignItems={"center"}
          p={1}
        >
          <Searchbar />
        </Grid>
        <Grid item p={2}>
          <Typography variant="h6" color={"#FFDA78"} fontWeight={"bold"}>
            {headingText}
          </Typography>
        </Grid>
        <Grid container p={2} spacing={2}>
          {message ? (
            <Grid
              container
              minHeight={"50vh"}
              xs={12}
              p={2}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                fontFamily={"Quicksand"}
                fontSize={30}
                fontWeight={600}
                color={"#FFF"}
              >
                {message}
              </Typography>
            </Grid>
          ) : (
            moviesToDisplay.map((item) => (
              <Grid item xs={12} sm={4} md={2} key={item.MovieName}>
                <Box
                  onClick={() => onClickMvWatchBtn(item)}
                  sx={{ textAlign: "center", cursor: "pointer" }}
                >
                  <img
                    src={item.MoviePoster}
                    alt={item.MovieName}
                    loading="lazy"
                    className="suggestedMovie"
                    style={{
                      maxWidth: "100%",
                      borderRadius: "8px",
                      border: "2px solid #FFDA78",
                    }}
                  />
                  <Typography variant="subtitle1" sx={{ mt: 1, color: "#FFF" }}>
                    {item.MovieName}
                  </Typography>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
        <Footer />
      </Grid>
    </>
  );
};
 
export default SuggestedOrSearchMovies;
 
 
 
 
 
 
 
 
 