import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom"; // Note: useNavigate is from react-router-dom, not react-router
import axios from "axios";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import endpoints from "../../Endpoints/endpoints";
import { Box, CircularProgress, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#000",
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  mainContent: {
    margin: "0 auto",
    maxWidth: "100%",
    minHeight: "60vh",
    padding: theme.spacing(3),
    textAlign: "center",
    color: "#fff",
  },
  aboutTitle: {
    marginBottom: theme.spacing(2),
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#FFDA78",
  },
  aboutDescription: {
    fontSize: "1rem",
    maxWidth: "100%",
    color: "#fff",
    // color: theme.palette.text.primary,
  },
}));

export default function AboutusOne() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [datastatus, setdatastatus] = useState("");

  const aboutUsApi = async () => {
    setdatastatus("loading");
    try {
      const res = await axios.get(`${endpoints.aboutUs}?title=About_Us`);
      setData(res.data);
      setdatastatus("success");
    } catch (error) {
      setdatastatus("errror");
    }
  };

  useEffect(() => {
    aboutUsApi();
  }, []);

  return (
    <div className={classes.container}>
      <Header />
      <Grid container className={classes.mainContent}>
        <Grid container justifyContent="center">
          <Grid xs={12} display={"flex"} flexDirection={"column"}>
            <Typography
              color={"#FFDA78"}
              sx={{ fontSize: { xs: 18, sm: 30 } }}
              fontWeight={700}
            >
              About Us
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={{ xs: "space-between" }}
              mb={5}
              mt={5}
            >
              <Typography
                fontSize={{ xs: "8px", sm: "15px" }}
                fontWeight={{ xs: 400, sm: 600 }}
              >
                IOTT ENTERTAINMENT AND EECOMERCE SERVICES PVT LTD
              </Typography>
              <Typography
                fontSize={{ xs: "8px", sm: "15px" }}
                fontWeight={{ xs: 400, sm: 600 }}
                color={"#FFDA78"}
              >
                Managing Director: Gudela Koteswarao
              </Typography>
            </Box>
          </Grid>

          {datastatus === "loading" && (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <CircularProgress color="warning" />
            </Grid>
          )}
          {datastatus === "success" && (
            <>
              <Typography
                textAlign={"justify"}
                sx={{ fontSize: { xs: 10, sm: 15 } }}
              >
                {data?.Description}
              </Typography>
              <Box
                mt={5}
                display={"flex"}
                justifyContent={"flex-start"}
                alignContent={"flex-start"}
                width={"100%"}
              >
                <Typography color={"#fff"} fontWeight={700}>
                  Address:{" "}
                </Typography>
                <Typography color={"fff"}  ml={2} textAlign={'justify'}>
                  Flat no: 503, 5th floor,
                  <Typography ml={2}>Eswar plaza, Beside SBI Bank,</Typography>
                  <Typography ml={2}>Dwaraka nagar,</Typography>
                  <Typography ml={2}>Visakapatnam-5330016, A.P.</Typography>
                </Typography>
              </Box>
            </>
          )}
          {datastatus === "error" && (
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              height={"40vh"}
            >
              <Typography variant="h5" color="#e2e2e2" textAlign="center">
                Failed to load Terms and Conditions. Please try again later.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
