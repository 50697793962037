import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import { PiWalletFill } from "react-icons/pi";
import { MdCurrencyRupee } from "react-icons/md";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "./index.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isActiveSearchBar } from "../../Redux/reducers/searchActiveReducer";
import { fetchTransactionDetails } from "../../Redux/reducers/wallet/getTransactionDetailsReducer";
import ScratchCard from "../../components/ScratchCard"; // Adjust the path to where your App component is located
import axios from "axios";
import Footer from "../../components/Footer";
import { getAuthData } from "../../utils/auth";
import endpoints from "../../Endpoints/endpoints";
import { GrInProgress } from "react-icons/gr";
import { fetchWalletAmount } from "../../Redux/reducers/wallet/getWalletAmountReducer";
import { mdi_banking } from "./mdi_bank.png";
import { BsBank } from "react-icons/bs";
import { FaSackDollar } from "react-icons/fa6";

const Wallet = () => {
  const dispatch = useDispatch();
  const authData = getAuthData();
  const [isOptionChosen, setIsOptionChosen] = useState(false);
  const [isScratched, setIsScratched] = useState(false);
  const [data, setData] = useState(null);
  const [billdata, setBillData] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const [amount, setAmount] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [OrderId, setOrderId] = useState("");
  const [rewardsData, setRewardsData] = useState("");
  const [errorReward, seterrorReward] = useState("");

  const [activeView, setActiveView] = useState("addMoney");

  const [snackstatus, setSnackstatus] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackstatus(false);
  };
  const boxStyles = {
    width: "70%",
    height: "20%",
    flexWrap: "wrap",
    paddingTop: 2,
    backgroundColor: "#FFDA78",
    background: "linear-gradient(45deg, #ffd700, #ff8c00)",
    borderRadius: 4,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    padding: 1, // Uncomment and adjust padding as needed
  };
  const { transaction_details, transactionDetailsFetchStatus } = useSelector(
    (state) => state.rootReducer.transactionDetailsReducer
  );

  const { wallet_amount } = useSelector(
    (state) => state.rootReducer.getWalletAmountReducer
  );
  console.log(wallet_amount);
  const crypto = require("crypto");

  const getDateDifference = (date) => {
    const currentDate = new Date();
    const movieDate = new Date(date);
    const differenceInTime = currentDate.getTime() - movieDate.getTime();

    const minutes = Math.floor(differenceInTime / (1000 * 60));
    if (minutes < 60) {
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    }

    const days = Math.floor(hours / 24);
    if (days < 30) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    }

    const years = Math.floor(months / 12);
    return years === 1 ? "1 year ago" : `${years} years ago`;
  };

  const handleViewChange = (view) => {
    setActiveView(view);
    if (view === "rewards") {
      setIsOptionChosen(true);
    } else {
      setIsOptionChosen(false);
    }
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  window.Crypto = window.Crypto || require("crypto").Crypto;

  const ccav = require("./ccavutil");

  const fetchrewardsData = async () => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/referral/get_rewardhistory?userid=${authData?.auth?.Id}`
      );
      // console.log(response.data);
      setRewardsData(response.data);
      seterrorReward("");
    } catch (error) {
      console.error("Error fetching data:", error);
      seterrorReward("Something went wrong");
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/payment/get_purchase_subscription_history?userid=${authData?.auth?.Id}`
      );
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const billsData = async () => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/payment/get_purchase_history?userid=${authData?.auth?.Id}`
      );
      console.log(response.data);
      setBillData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (authData?.auth) {
      fetchData();

      billsData();

      getOrderId();

      fetchrewardsData();

      dispatch(fetchWalletAmount(authData?.auth?.Id));

      dispatch(fetchTransactionDetails(authData?.auth?.Id));
    }

    // const interval = setInterval(() => {
    //   setCurrentDateTime(new Date());
    // }, 1000);

    // return () => clearInterval(interval);
  }, []);

  const handleAmountChange = (e) => {
    const enteredAmount = e.target.value;
    if (parseInt(enteredAmount) < 30 && enteredAmount !== "") {
      setValidationMessage("Please enter at least 30 rupees.");
    } else {
      setValidationMessage("");
    }

    setSelectedAmount("");
    setAmount(enteredAmount);
  };

  const handleTopUpOptionChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedAmount(selectedValue);
    setAmount(selectedValue);
    setAmount("");
    setValidationMessage("");
  };

  const getOrderId = () => {
    axios
      .get(`${endpoints.getOrderId}`, {
        headers: {
          Authorization: `Bearer ${authData?.authToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOrderId(response.data.OrderId);
        }
      })
      .catch((err) => console.log(err));
  };

  let url = "";
  const initiateTransaction = async () => {
    await axios
      .post(
        `${endpoints.initiateTransaction}`,
        {
          UserId: authData?.auth?.Id,
          OrderId: OrderId,
          Amount: amount,
        },
        {
          headers: {
            Authorization: `Bearer ${authData?.authToken}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
        }
      })
      .catch((err) => console.log(err));
  };
  const setPmtUrl = async () => {
    if (typeof initiateTransaction === "function") {
      initiateTransaction();
    } else {
      console.error("initiateTransaction is not defined");
      return;
    }

    const body =
      "merchant_id=708495&order_id=" +
      OrderId +
      "&redirect_url=https://deve.iott.co.in/MarchantPage2?Userid=" +
      authData?.auth?.Id +
      "&cancel_url=https://deve.iott.co.in/MarchantPage2?userid=" +
      authData?.auth?.Id +
      "&OrderId=" +
      OrderId +
      "&amount=" +
      amount +
      "&currency=INR";

    const workingKey = "6E824886AB948C420B7D6E0E2B9333F6";
    const accessCode = "AVVJ56IK18AD79JVDA";

    const md5 = crypto.createHash("md5").update(workingKey).digest();
    const keyBase64 = Buffer.from(md5).toString("base64");

    const ivBase64 = Buffer.from([
      0x00, 0x01, 0x02, 0x03, 0x04, 0x05, 0x06, 0x07, 0x08, 0x09, 0x0a, 0x0b,
      0x0c, 0x0d, 0x0e, 0x0f,
    ]).toString("base64");

    const encRequest = ccav.encrypt(body, keyBase64, ivBase64);

    url =
      "https://secure.ccavenue.com/transaction.do?command=initiateTransaction&encRequest=" +
      encRequest +
      "&access_code=" +
      accessCode;

    let form = document.forms["redirect"];
    if (!form) {
      form = document.createElement("form");
      form.setAttribute("name", "redirect");
      form.setAttribute("method", "POST");
      document.body.appendChild(form);
    }

    form.action = url;
    form.submit();
  };

  const handleProceed = () => {
    if (!selectedAmount && (amount === "" || parseInt(amount) < 30)) {
      setValidationMessage(
        // "Please enter at least 30 rupees or select a top-up."
        "Please enter at least 30 rupees"
      );
    } else {
      // setSuccessMessage("Top-up successful!");
      if (OrderId) {
        setPmtUrl();
      } else {
        setSnackstatus(true);
      }
    }
  };

  /////////////////////////// Refer & Earn Bank Details /////////////////////////////
  const [formValues, setFormValues] = useState({
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    branch: "",
    name: "",
  });

  const [formErrors, setFormErrors] = useState({
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    branch: "",
    name: "",
  });
  const userId = authData?.auth?.Id;
  const [addMessage, setAddMessage] = React.useState("");
  const [bankNames, setBankNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [totalBonus, setTotalBonus] = useState(0);
  const [error, setError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDetailsSaved, setIsDetailsSaved] = useState(false); // Indicates if details have been saved
  const [savedDetails, setSavedDetails] = useState({}); // State to store saved bank details
  const [withdrawHistory, setWithdrawHistory] = useState({
    WithDrawAmmount: "",
    date: "",
  });

  useEffect(() => {
    const fetchBankNames = async () => {
      try {
        const response = await axios.get(
          "https://api.iott.co.in/api/payment/GetBankNames"
        );
        setBankNames(response.data); // Assuming the response is an array of bank names
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching bank names:", error);
        setIsLoading(false);
      }
    };
    fetchBankNames();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.iott.co.in/api/payment/GetBonusWithDrawHistoryByUserId?userId=28837"
        );
        setWithdrawHistory(response.data);
        console.log(response.data);
      } catch (err) {
        setError(err);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!formValues.bankName) errors.bankName = "Bank name is required";
    if (!formValues.accountNumber)
      errors.accountNumber = "Account number is required";
    if (!formValues.ifscCode) errors.ifscCode = "IFSC code is required";
    if (!formValues.branch) errors.branch = "Branch is required";
    if (!formValues.name) errors.name = "Name is required";

    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setSavedDetails(formValues);
      setIsDetailsSaved(true);
      setIsEditMode(false);
      setAddMessage("Details saved successfully!");
    } else {
      setAddMessage(""); // Clear the success message on error
    }
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleSave = () => {
    if (validateForm()) {
      setAddMessage("Your bank details have been added successfully!");
      // console.log("Form Submitted:", formValues);
    } else {
      setAddMessage("");
    }

    try {
      const postData = {
        UserId: userId,
        BankName: formValues.bankName,
        AccountNumber: formValues.accountNumber,
        IFSCCode: formValues.ifscCode,
        Branch: formValues.branch,
        Name: formValues.name,
      };

      const response = axios.post(
        "https://api.iott.co.in/api/payment/AddUserTransactionDetails",
        postData
      );
      // setAddMessage("Your bank details have been added successfully.");
      console.log("Data saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving data:", error);
      setAddMessage(
        "An error occurred while saving your details. Please try again."
      );
    }
  };

  // const fetchBonusEarnings = async () => {
  //   try {
  //     const userId = authData?.auth?.Id;
  //     // Assuming the ReferredByUserId is the same as the current user's ID
  //     const response = await axios.get(
  //       https://api.iott.co.in/api/Payment/GetTotalBonusAmountByReferredByUserId?ReferredByUserId=${userId}
  //     );

  //     if (response.status === 200) {
  //       console.log(response);
  //       setTotalBonus(response.data);
  //     } else {
  //       throw new Error("Failed to fetch total earnings.");
  //     }
  //   } catch (error) {
  //     setError("Error fetching total earnings. Please try again later.");
  //     console.error("Error fetching total earnings:", error);
  //   }
  // };
  /////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Header />

      <div className="main-container-wallet">
        {/* <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          minHeight={"100%"}
          p={2}
        > */}
        <Grid
          style={{
            backgroundImage: `url(${"https://bucketblob.iott.co.in/Icons/bgImage.jpg"})`,
          }}
          item
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box width={"90%"} mb={10}>
            <Box
              display={"flex"}
              alignItems={"center"}
              alignSelf={"flex-start"}
              mb={1}
            >
              <Typography
                fontSize={{ xs: 20, sm: 26, md: 34 }}
                color={"#FFDA78"}
                mr={1}
                fontWeight={900}
              >
                WALLET
              </Typography>
              <PiWalletFill color="#FFDA78" fontSize={34} />
            </Box>

            <Box
              bgcolor={"#FFDA78"}
              display={"flex"}
              flexDirection={{ xs: "column", md: "row" }}
              width={"100%"}
              maxHeight={"100%"}
              borderRadius={6}
              // p={3}
            >
              {/* ============== control part ==================== */}

              <Box
                width={{ xs: "100%", md: "50%" }}
                borderRight={{ xs: "none", md: "1px solid #010113" }}
                borderBottom={{ md: "none", xs: "1px solid #010113" }}
              >
                <Box display={"flex"} flexDirection={"row"}>
                  {wallet_amount && wallet_amount !== 0 ? (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      ml={6}
                      mt={1}
                    >
                      {" "}
                      <Typography
                        fontWeight={600}
                        fontSize={{ xs: "20px", sm: "30px" }}
                      >
                        BALANCE :{" "}
                      </Typography>
                      {/* <MdCurrencyRupee fontWeight={600} fontSize={20} /> */}
                      <Typography
                        fontWeight={600}
                        fontSize={{ xs: "20px", sm: "30px" }}
                      >
                        ₹{wallet_amount}
                      </Typography>
                    </Box>
                  ) : (
                    //  {wallet_amount ===0 && (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      ml={5}
                      mt={1}
                    >
                      {" "}
                      <Typography
                        fontWeight={600}
                        fontSize={{ xs: "20px", sm: "30px" }}
                      >
                        BALANCE :{" "}
                      </Typography>
                      {/* <MdCurrencyRupee fontWeight={600} fontSize={{xs:'20px',sm:'60px'}}/> */}
                      <Typography
                        fontWeight={600}
                        fontSize={{ xs: "20px", sm: "30px" }}
                      >
                        ₹{wallet_amount || 0}
                      </Typography>
                    </Box>
                  )}

                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    ml={6}
                    mt={1}
                  >
                    {" "}
                    <Typography
                      fontWeight={600}
                      fontSize={{ xs: "20px", sm: "30px" }}
                    >
                      BONUS :{" "}
                    </Typography>
                    {/* <MdCurrencyRupee fontWeight={600} fontSize={20} /> */}
                    <Typography
                      fontWeight={600}
                      fontSize={{ xs: "20px", sm: "30px" }}
                    >
                      ₹{totalBonus}
                      {/* ₹0 */}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  // pl={{ xs: 1 }}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  flexWrap={"wrap"}
                  mt={5}
                >
                  <Box
                    m={2}
                    className="selected-event"
                    border={activeView === "rewards" && "2px solid black"}
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("rewards")}
                  >
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/rewards.png"}
                      alt="rewards"
                      className="wallet-images"
                    />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                    >
                      Rewards
                    </Typography>
                  </Box>

                  <Box
                    m={2}
                    className="selected-event"
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"#FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("addMoney")}
                    border={activeView === "addMoney" && "2px solid black"}
                  >
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/top-up.png"}
                      alt="add-money"
                      className="wallet-images"
                    />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                    >
                      Top-Up
                    </Typography>
                  </Box>

                  <Box
                    m={2}
                    className="selected-event"
                    border={
                      activeView === "paymentHistory" && "2px solid black"
                    }
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("paymentHistory")}
                  >
                    <img
                      src={
                        "https://bucketblob.iott.co.in/Icons/Payment history.png"
                      }
                      alt="payment-history"
                      className="wallet-images"
                    />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                      textAlign={"center"}
                    >
                      Payment History
                    </Typography>
                  </Box>
                  <Box
                    m={2}
                    className="selected-event"
                    border={activeView === "bill" && "2px solid black"}
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("bill")}
                  >
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/bill.png"}
                      alt="bill"
                      className="wallet-images"
                    />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                    >
                      Bill
                    </Typography>
                  </Box>

                  <Box
                    m={2}
                    className="selected-event"
                    border={
                      activeView === "subscriptionDetails" && "2px solid black"
                    }
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("subscriptionDetails")}
                  >
                    <img
                      src={
                        "https://bucketblob.iott.co.in/Icons/Payment history.png"
                      }
                      alt="payment-history"
                      className="wallet-images"
                    />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                      textAlign={"center"}
                    >
                      Subscription Details
                    </Typography>
                  </Box>

                  {/* <Box
                    m={2}
                    className="selected-event"
                    border={activeView === "bank" && "2px solid black"}
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("bank")}
                  > 
                    <BsBank fontSize={"50px"} />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                    >
                      Bank
                    </Typography>
                  </Box> */}

                  {/* <Box
                    m={2}
                    className="selected-event"
                    border={activeView === "withdraw" && "2px solid black"}
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("withdraw")}
                  > 
                    <FaSackDollar fontSize={"50px"} />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                    >
                      Withdraw
                    </Typography>
                  </Box> */}
                </Box>
              </Box>

              {/* ============== details part ==================== */}

              {activeView === "addMoney" && (
                <Box
                  height={"100%"}
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 2, md: 4 }}
                  pl={{ xs: 0, md: 4 }}
                  pt={0}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={{ xs: 0, sm: 2 }}
                    >
                      ADD MONEY{" "}
                    </Typography>
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/top-up.png"}
                      alt="add-money"
                      className="icon"
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    mt={{ xs: 0, sm: 6 }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography
                        fontFamily={"Merriweather"}
                        fontWeight={700}
                        fontSize={{ xs: 14, sm: 30 }}
                        mb={2}
                      >
                        Top Up
                      </Typography>
                      <input
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        className="enter-amount"
                        placeholder="Enter Min 30 rupees"
                        // disabled={selectedAmount !== ""}
                      />

                      {/* <Box mt={2}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={!amount ? selectedAmount : ""}
                            onChange={handleTopUpOptionChange}
                          >
                            <FormControlLabel
                              value={100}
                              control={<Radio />}
                              label="100"
                            />
                            <FormControlLabel
                              value={500}
                              control={<Radio />}
                              label="500"
                            />
                            <FormControlLabel
                              value={1000}
                              control={<Radio />}
                              label="1000"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box> */}
                      <Box mt={{ xs: 0, sm: 3 }} mb={{ xs: 0, sm: 3 }}>
                        <button className="proceed-btn" onClick={handleProceed}>
                          Proceed
                        </button>
                      </Box>

                      {validationMessage && (
                        <Typography
                          color="error"
                          fontFamily={"Quicksand"}
                          fontWeight={700}
                          textAlign={"center"}
                          width={250}
                        >
                          {validationMessage}
                        </Typography>
                      )}

                      {/* Success Message */}
                      {successMessage && (
                        <Typography
                          pt={3}
                          color="green"
                          fontFamily={"Quicksand"}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          {successMessage}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}

              {activeView === "paymentHistory" && (
                <Grid
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 3, md: 2 }}
                  pl={{ xs: 2, md: 4 }}
                  pt={0}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mb={2}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={2}
                    >
                      Payment History
                    </Typography>
                    <img
                      src={
                        "https://bucketblob.iott.co.in/Icons/Payment history.png"
                      }
                      alt="payment-history"
                      className="icon"
                    />
                  </Box>
                  {transactionDetailsFetchStatus === "loading" && (
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <CircularProgress color="info" />
                    </Grid>
                  )}
                  {transactionDetailsFetchStatus === "success" &&
                  transaction_details?.length > 0 &&
                  !transaction_details[0]?.message ? (
                    <Grid
                      bgcolor={"#000"}
                      borderRadius={4}
                      maxHeight={{ xs: "180px", sm: "300px", md: "300px" }}
                      border={"1px solid #fff"}
                      p={2}
                      sx={{
                        overflowY: "auto",
                        overflowX: "auto",
                        "&::-webkit-scrollbar": {
                          width: "4px", // Set your custom scrollbar width here
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#ffda78",
                          borderRadius: "2px",
                        },
                      }}
                    >
                      <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mb={2}
                      >
                        <Typography
                          width={{ xs: "55%" }}
                          fontWeight={600}
                          color={"#ffda78"}
                          fontSize={{ xs: "9px", sm: "18px" }}
                        >
                          TRANSACTION DETAILS
                        </Typography>
                        <Typography
                          width={"15%"}
                          fontWeight={600}
                          color={"#ffda78"}
                          fontSize={{ xs: "9px", sm: "18px" }}
                        >
                          AMOUNT
                        </Typography>
                        <Typography
                          width={"15%"}
                          fontWeight={600}
                          color={"#ffda78"}
                          fontSize={{ xs: "9px", sm: "18px" }}
                        >
                          STATUS
                        </Typography>
                      </Box>
                      {transaction_details?.map((transaction, index) => (
                        <Box
                          key={index}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          borderBottom={"1px solid #ffda78"}
                          pb={1}
                          pt={1}
                        >
                          <Box
                            width={"55%"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"flex-start"}
                          >
                            <Typography
                              color={"#ffda78"}
                              fontFamily={"Quicksand"}
                              // fontSize={12}
                              fontWeight={{ xs: 500, sm: 700 }}
                              fontSize={{ xs: "8px", sm: "18px" }}
                            >
                              OrderId:{" "}
                              <span style={{ fontWeight: 400 }}>
                                {transaction.Order_Id}
                              </span>
                            </Typography>
                            <Typography
                              color={"#ffda78"}
                              fontFamily={"Quicksand"}
                              fontSize={{ xs: "8px", sm: "18px" }}
                              fontWeight={{ xs: 500, sm: 700 }}
                            >
                              Transaction Id:{" "}
                              <span style={{ fontWeight: 400 }}>
                                {transaction.TrancId || " ----------- "}
                              </span>
                            </Typography>
                            <Typography
                              color={"#ffda78"}
                              fontFamily={"Quicksand"}
                              fontSize={{ xs: "8px", sm: "18px" }}
                              fontWeight={{ xs: 500, sm: 700 }}
                            >
                              Date:{" "}
                              <span style={{ fontWeight: 400 }}>
                                {getDateDifference(transaction?.DateTime) ||
                                  " --- "}
                              </span>
                            </Typography>
                          </Box>
                          <Typography
                            width={"15%"}
                            color={"#ffda78"}
                            fontFamily={"Quicksand"}
                            fontSize={{ xs: "8px", sm: "18px" }}
                            fontWeight={600}
                            textAlign={"center"}
                          >
                            ₹{transaction?.Amount}
                          </Typography>
                          <Typography
                            width={"15%"}
                            color={"#ffda78"}
                            fontFamily={"Quicksand"}
                            fontSize={12}
                            fontWeight={400}
                            textAlign={"center"}
                          >
                            {transaction.Status === "Success" ? (
                              <img
                                src={
                                  "https://bucketblob.iott.co.in/Icons/correct.png"
                                }
                                alt="Correct"
                                style={{ width: 20, height: 20 }}
                              />
                            ) : transaction.Status === "Initiated" ? (
                              <GrInProgress fontSize={18} color="#82E17B" />
                            ) : (
                              <img
                                src={
                                  "https://bucketblob.iott.co.in/Icons/failed.png"
                                }
                                alt="Failed"
                                style={{ width: 20, height: 20 }}
                              />
                            )}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                  ) : (
                    transactionDetailsFetchStatus !== "loading" && (
                      <Grid
                        bgcolor={"#000"}
                        borderRadius={4}
                        minHeight={"300px"}
                        border={"1px solid #fff"}
                        p={2}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Typography
                          sx={{ color: "#fff" }}
                          fontSize={{ xs: "14px", sm: "23px" }}
                          fontWeight={{ xs: 600, sm: 800 }}
                          textAlign={"center"}
                        >
                          You have no Payments yet!
                        </Typography>
                      </Grid>
                    )
                  )}
                  {transactionDetailsFetchStatus === "failed" && (
                    <Typography color={"#fff"}>
                      Failed to load transaction details
                    </Typography>
                  )}
                </Grid>
              )}

              {activeView === "rewards" && isOptionChosen && !isScratched && (
                <Box
                  height={"100%"}
                  width={{ xs: "80%", md: "50%" }}
                  justifyContent={"center"}
                  p={{ xs: 3, md: 2 }}
                  pt={0}
                  pl={{ sm: "10%", md: "none" }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={{ xs: 1, sm: 2 }}
                    >
                      Rewards
                    </Typography>
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/rewards.png"}
                      alt="rewards"
                      className="icon"
                    />
                  </Box>
                  <Grid
                    bgcolor={"#000"}
                    borderRadius={4}
                    maxHeight={{ xs: "150px", sm: "300px", md: "300px" }}
                    border={"1px solid #fff"}
                    p={2}
                    sx={{
                      overflowY: "auto",
                      overflowX: "auto",
                      "&::-webkit-scrollbar": {
                        width: "4px", // Set your custom scrollbar width here
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ffda78",
                        borderRadius: "2px",
                      },
                    }}
                  >
                    {errorReward ? (
                      <Box
                        bgcolor={"#000"}
                        borderRadius={4}
                        height={{ xs: "150px", sm: "300px", md: "200px" }}
                      >
                        <Typography
                          fontFamily={"Quicksand"}
                          fontWeight={{ xs: 500, sm: 700 }}
                          fontSize={18}
                          color={"#fff"}
                          textAlign={"center"}
                        >
                          {errorReward}
                        </Typography>
                      </Box>
                    ) : rewardsData.length > 0 &&
                      (rewardsData[0]?.message == undefined ||
                        rewardsData[0]?.message == null) ? (
                      rewardsData?.map((reward, index) => (
                        <Box sx={boxStyles}>
                          <Typography
                            key={index}
                            fontFamily={"Quicksand"}
                            fontWeight={700}
                            fontSize={18}
                            color={"#000"}
                          >
                            Referralcode: {reward.Referralcode}
                          </Typography>

                          <Typography
                            key={index}
                            fontFamily={"Quicksand"}
                            fontWeight={700}
                            fontSize={18}
                            color={"#000"}
                          >
                            BonusAmount: ₹ {reward.BonusAmount}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        minHeight={{ xs: "140px", sm: "280px" }}
                      >
                        <Typography
                          sx={{ color: "#fff" }}
                          fontSize={{ xs: "14px", sm: "23px" }}
                          fontWeight={{ xs: 600, sm: 800 }}
                          textAlign={"center"}
                        >
                          You have no Rewards..!
                        </Typography>
                      </Box>
                    )}

                    {/* <ScratchCard onScratch={() => setIsScratched(true)} /> */}
                  </Grid>
                </Box>
              )}

              {activeView === "bill" && (
                <Grid
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 2, md: 4 }}
                  pl={{ xs: 3, md: 4 }}
                  pt={0}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={{ xs: 0 }}
                    >
                      Bill
                    </Typography>
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/bill.png"}
                      alt="bill"
                      className="icon"
                    />
                  </Box>
                  <Grid
                    gap={2}
                    bgcolor={"#000"}
                    borderRadius={4}
                    maxHeight={{ xs: "40vh", md: "300px" }}
                    border={"1px solid #fff"}
                    p={1}
                    sx={{
                      overflowY: "auto",
                      overflowX: "auto",
                      "&::-webkit-scrollbar": {
                        width: "4px", // Set your custom scrollbar width here
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ffda78",
                        borderRadius: "2px",
                      },
                    }}
                  >
                    {billdata?.length > 0 &&
                    billdata[0]?.message === undefined ? (
                      <Grid
                        height={"90%"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        gap={0.5}
                        pb={2}
                      >
                        {billdata?.map((movie, index) => (
                          <Grid
                            key={index}
                            className="bill-card"
                            bgcolor={"#FFDA78"}
                            borderRadius={2}
                            display={"flex"}
                            flexDirection={"column"}
                            // width={"100%"}
                            mt={1}
                            mb={1}
                          >
                            <Typography
                              pt={2}
                              pb={1}
                              textAlign={"center"}
                              fontFamily={"Quicksand"}
                              fontWeight={800}
                              fontSize={13}
                              color={"#000"}
                              borderBottom={"2px solid #000"}
                            >
                              Movie Name: {movie?.MovieName}
                            </Typography>
                            <Box
                              pt={2}
                              pb={2}
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <Typography
                                textAlign={"justify"}
                                fontFamily={"Quicksand"}
                                fontWeight={800}
                                fontSize={15}
                                color={"#000"}
                              >
                                Purchase Amount: {movie?.PurchaseAmount}
                              </Typography>
                              <Typography
                                textAlign={"justify"}
                                fontFamily={"Quicksand"}
                                fontWeight={800}
                                fontSize={15}
                                color={"#000"}
                              >
                                Date:{" "}
                                {getDateDifference(movie.DateTime) || " - "}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Grid
                        minHeight={"280px"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        pb={2}
                      >
                        <Typography
                          sx={{ color: "#fff" }}
                          fontSize={{ xs: "14px", sm: "23px" }}
                          fontWeight={{ xs: 600, sm: 800 }}
                          textAlign={"center"}
                        >
                          You have no bills yet!
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              {activeView === "subscriptionDetails" && (
                <Box
                  // height={{ xs: "50%", sm: "100%" }}
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 2, md: 4 }}
                  pl={{ xs: 0, md: 4 }}
                  pt={0}
                >
                  {data && (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Box display={"flex"} flexDirection={"row"} gap={2}>
                        <Typography
                          fontFamily={"Merriweather"}
                          fontWeight={700}
                          fontSize={{ xs: 14, sm: 30 }}
                          // mb={2}
                        >
                          Subscription Details
                        </Typography>
                        <img
                          src={
                            "https://bucketblob.iott.co.in/Icons/Payment history.png"
                          }
                          alt="payment-history"
                          className="icon"
                          style={{ marginBottom: "16px" }}
                        />
                      </Box>
                      {transactionDetailsFetchStatus === "loading" && (
                        <Typography color={"#fff"}>Loading...</Typography>
                      )}
                      {transactionDetailsFetchStatus !== "loading" && data && (
                        <>
                          {data?.length > 0  &&
                          data[0]?.SubscriptionPlan ?  (
                            <Box
                              bgcolor={"#000"}
                              borderRadius ={4}
                              width={{xs:'80%',sm:'95%'}}
                              maxHeight={{xs:'200px',sm:'300px'}}
                              border={"1px solid #fff"}
                              p={2}
                              sx={{
                                overflowY: "auto",
                                overflowX: "auto",
                                "&::-webkit-scrollbar": {
                                  width: "4px", // Set your custom scrollbar width here
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#ffda78",
                                  borderRadius: "2px",
                                },
                              }}
                            >
                              <Box
                                width={"100%"}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                // mb={2}
                              >
                                <Typography
                                  width={"60%"}
                                  fontWeight={700}
                                  color={"#ffda78"}
                                  fontSize={{ xs: "10px", sm: "20px" }}
                                >
                                  SUBSCRIPTION DETAILS
                                </Typography>
                                <Typography
                                  width={"15%"}
                                  fontWeight={700}
                                  color={"#ffda78"}
                                  fontSize={{ xs: "10px", sm: "20px" }}
                                >
                                  AMOUNT
                                </Typography>
                              </Box>
                              {data?.map((subscription, index) => (
                                <Box
                                  key={index}
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  borderBottom={"1px solid #ffda78"}
                                  pb={1}
                                  pt={1}
                                >
                                  <Box
                                    width={"60%"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={"flex-start"}
                                  > 
                                    <Typography
                                      color={"#ffda78"}
                                      fontFamily={"Quicksand"}
                                      fontSize={{ xs: "9px", sm: "18px" }}
                                      fontWeight={{ xs: 600 }}
                                    >
                                      Language :{" "}
                                      <span style={{ fontWeight: 400 }}>
                                        {subscription?.Language}
                                      </span>
                                    </Typography>
                                    <Typography
                                      color={"#ffda78"}
                                      fontFamily={"Quicksand"}
                                      fontSize={{ xs: "9px", sm: "18px" }}
                                      fontWeight={{ xs: 600 }}
                                    >
                                      SubscriptionPlan:{" "}
                                      <span style={{ fontWeight: 400 }}>
                                        {subscription?.SubscriptionPlan}
                                      </span>
                                    </Typography>
                                    <Typography
                                      color={"#ffda78"}
                                      fontFamily={"Quicksand"}
                                      fontSize={{ xs: "9px", sm: "18px" }}
                                      fontWeight={{ xs: 600 }}
                                    >
                                      Date :{" "}
                                      <span style={{ fontWeight: 400 }}>
                                        {subscription?.DateTime}
                                      </span>
                                    </Typography>
                                  </Box>
                                  <Typography
                                    width={"15%"}
                                    color={"#ffda78"}
                                    fontFamily={"Quicksand"}
                                    fontSize={{xs:14,sm:18}}
                                    fontWeight={600}
                                    textAlign={"center"}
                                  >
                                    ₹ {subscription?.PurchaseAmount}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          ) : (
                            <Box
                              bgcolor={"#000"}
                              borderRadius={4}
                              width={{xs:'40%',sm:'80%'}}
                              minHeight={"250px"}
                              border={"1px solid #fff"}
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"center"}
                              p={2}
                              overflow={"auto"}
                            >
                              <Typography
                                sx={{ color: "#fff" }}
                                fontSize={20}
                                fontWeight={800}
                                textAlign={"center"}
                              >
                                You have no subscription plans
                              </Typography>
                            </Box>
                          )}
                        </>
                      )} 
                    </Box>
                  )}
                </Box>
              )}

              {/* {activeView === "bank" && (
                <Grid
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 3, md: 2 }}
                  pl={{ xs: 2, md: 4 }}
                  pt={0}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-around"}
                    mb={2}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={2}
                    >
                      Bank Details
                    </Typography> 
                    <BsBank fontSize={"30px"} />
                  </Box>

                  <Grid
                    xs={12}
                    bgcolor={"#000"}
                    borderRadius={4}
                    minHeight={{ xs: "180px", sm: "300px", md: "300px" }}
                    border={"1px solid #fff"}
                    display={"flex"}
                    flexDirection={"column"}
                    p={2}
                    sx={{
                      overflowY: "auto",
                      overflowX: "auto",
                      "&::-webkit-scrollbar": {
                        width: "4px", // Set your custom scrollbar width here
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ffda78",
                        borderRadius: "2px",
                      },
                    }}
                  >
                    {isDetailsSaved && !isEditMode ? (
                      // View Mode
                      <Grid display={"flex"} justifyContent={"center"}>
                        <Box
                          width={"60%"}
                          bgcolor={"#fff"}
                          borderRadius={2}
                          p={3}
                        >
                          <Box sx={{ color: "#000", fontSize: "15px", mb: 1 }}>
                            <strong>Bank Name:</strong> {savedDetails.bankName}
                          </Box>
                          <Box sx={{ color: "#000", fontSize: "15px", mb: 1 }}>
                            <strong>Account Number:</strong>{" "}
                            {savedDetails.accountNumber}
                          </Box>
                          <Box sx={{ color: "#000", fontSize: "15px", mb: 1 }}>
                            <strong>IFSC Code:</strong> {savedDetails.ifscCode}
                          </Box>
                          <Box sx={{ color: "#000", fontSize: "15px", mb: 1 }}>
                            <strong>Branch:</strong> {savedDetails.branch}
                          </Box>
                          <Box sx={{ color: "#000", fontSize: "15px", mb: 1 }}>
                            <strong>Name:</strong> {savedDetails.name}
                          </Box>
                          <Box display={"flex"} justifyContent={"flex-end"}>
                            <Button
                              variant="outlined"
                              sx={{ mt: 2, color: "#000" }}
                              onClick={handleEdit}
                            >
                              Edit
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    ) : (
                      <>
                        <Box ml={15}>
                          <InputLabel
                            sx={{ color: "#F2C94C", fontSize: "15px" }}
                          >
                            Bank Name
                          </InputLabel>
                          <FormControl variant="outlined" sx={{ mb: 1 }}>
                            <Select
                              // defaultValue=""
                              name="bankName"
                              value={formValues.bankName}
                              onChange={handleChange}
                              sx={{
                                borderRadius: "50px",
                                height: "30px",
                                width: "28ch",
                                color: "#000",
                                backgroundColor: "white",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: "none", // No border
                                },
                                "& .MuiSvgIcon-root": {
                                  color: "black", 
                                },
                              }}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    maxHeight: 200, 
                                    width: "10ch", 
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">Select Bank</MenuItem>
                              {isLoading ? (
                                <MenuItem value="" disabled>
                                  Loading...
                                </MenuItem>
                              ) : (
                                bankNames.map((bank, index) => (
                                  <MenuItem key={index} value={bank}>
                                    {bank}
                                  </MenuItem>
                                ))
                              )}
                            </Select>
                            {formErrors.bankName && (
                              <Box
                                sx={{ color: "red", fontSize: "14px", mt: 1 }}
                              >
                                {formErrors.bankName}
                              </Box>
                            )}
                          </FormControl>
                          <InputLabel
                            sx={{ color: "#F2C94C", fontSize: "15px" }}
                          >
                            Account Number
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="accountNumber"
                            value={formValues.accountNumber}
                            onChange={handleChange}
                            // label="Name"
                            sx={{
                              mb: 1,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                backgroundColor: "white",
                                width: "28ch",
                                color: "#000",
                                height: 30,
                                "& fieldset": { border: "none" }, 
                                "& input": { color: "black" }, 
                              },
                              "& .MuiInputLabel-root": {
                                color: "#F2C94C", 
                              },
                            }}
                          />
                          {formErrors.accountNumber && (
                            <Box sx={{ color: "red", fontSize: "14px", mt: 1 }}>
                              {formErrors.accountNumber}
                            </Box>
                          )}
                          <InputLabel
                            sx={{ color: "#F2C94C", fontSize: "15px" }}
                          >
                            IFSC Code
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="ifscCode"
                            value={formValues.ifscCode}
                            onChange={handleChange}
                            // label="Name"
                            sx={{
                              mb: 1,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                backgroundColor: "white",
                                width: "28ch",
                                height: 30,
                                "& fieldset": { border: "none" }, 
                                "& input": { color: "black" }, 
                              },
                              "& .MuiInputLabel-root": {
                                color: "#F2C94C",
                              },
                            }}
                          />
                          {formErrors.ifscCode && (
                            <Box sx={{ color: "red", fontSize: "14px", mt: 1 }}>
                              {formErrors.ifscCode}
                            </Box>
                          )}
                          <InputLabel
                            sx={{ color: "#F2C94C", fontSize: "15px" }}
                          >
                            Branch
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="branch"
                            value={formValues.branch}
                            onChange={handleChange}
                            // label="Name"
                            sx={{
                              mb: 1,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                backgroundColor: "white",
                                width: "28ch",
                                height: 30,
                                "& fieldset": { border: "none" }, 
                                "& input": { color: "black" },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#F2C94C", 
                              },
                            }}
                          />
                          {formErrors.branch && (
                            <Box sx={{ color: "red", fontSize: "14px", mt: 1 }}>
                              {formErrors.branch}
                            </Box>
                          )}
                          <InputLabel
                            sx={{ color: "#F2C94C", fontSize: "15px" }}
                          >
                            Name
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="name"
                            value={formValues.name}
                            onChange={handleChange}
                            // label="Name"
                            sx={{
                              mb: 1,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                backgroundColor: "white",
                                width: "28ch",
                                height: 30,
                                "& fieldset": { border: "none" }, 
                                "& input": { color: "black" }, 
                              },
                              "& .MuiInputLabel-root": {
                                color: "#F2C94C", 
                              },
                            }}
                          />
                          {formErrors.name && (
                            <Box sx={{ color: "red", fontSize: "14px", mt: 1 }}>
                              {formErrors.name}
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: 2,
                            mt: 3,
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#F2C94C",
                              color: "black",
                              borderRadius: "20px",
                              padding: "8px 24px",
                              "&:hover": {
                                backgroundColor: "#e1b933", 
                              },
                            }}
                            onClick={handleSave}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{
                              borderColor: "white",
                              color: "#000",
                              borderRadius: "20px",
                              padding: "8px 24px",
                              "&:hover": {
                                borderColor: "#e1b933",
                                color: "#e1b933", 
                              },
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </>
                    )}

                   
                    {addMessage && (
                      <Box sx={{ mt: 3, textAlign: "center", color: "green" }}>
                        {addMessage}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )} */}

              {/* {activeView === "withdraw" && (
                <Grid
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 3, md: 2 }}
                  pl={{ xs: 2, md: 4 }}
                  pt={0}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-around"}
                    mb={2}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={2}
                    >
                      Withdraw
                    </Typography>
                    <FaSackDollar fontSize={"30px"} />
                  </Box>
                  <Grid
                    bgcolor={"#000"}
                    borderRadius={4}
                    maxHeight={{ xs: "180px", sm: "300px", md: "300px" }}
                    border={"1px solid #fff"}
                    p={2}
                    sx={{
                      overflowY: "auto",
                      overflowX: "auto",
                      "&::-webkit-scrollbar": {
                        width: "4px", 
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ffda78",
                        borderRadius: "2px",
                      },
                    }}
                  >
                    {withdrawHistory.length === 0 ? (
                      <Typography color="#fff">
                        No withdrawals found.
                      </Typography>
                    ) : (
                      withdrawHistory.map((withdrawal, index) => (
                        <Grid
                          xs={8}
                          key={index}
                          p={2}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box
                            bgcolor={"#fff"}
                            height={60}
                            width={"100%"}
                            borderRadius={"5px"}
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-around"}
                            alignItems={"center"}
                          >
                            <Typography
                              fontFamily={"Quicksand"}
                              fontWeight={600}
                              fontSize={"16px"}
                              color={"#000"}
                            >
                              Withdrawal Amount Received
                            </Typography>
                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography
                                fontFamily={"Quicksand"}
                                textAlign={"center"}
                                fontSize={"18px"}
                                fontWeight={700}
                                color={"green"}
                              >
                                {withdrawal.WithDrawAmmount}
                              </Typography>
                              <Typography
                                fontFamily={"Quicksand"}
                                fontSize={"8px"}
                                fontWeight={700}
                                color={"#000"}
                              >
                                {withdrawal.WithDrawDate}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))
                    )} 
                  </Grid>
                </Grid>
              )} */}
            </Box>
          </Box>
          <Snackbar
            open={snackstatus}
            autoHideDuration={4000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="warning"
              sx={{ width: "100%" }}
            >
              Something went wrong! Try after some time.
            </Alert>
          </Snackbar>
        </Grid>
        <Footer />
      </div>
    </>
  );
};

export default Wallet;
