import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import Register from "./Pages/AuthPages/Register";
import Login from "./Pages/AuthPages/Login";
import ForgotPassword from "./Pages/AuthPages/ForgotPassword"; 
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import Wallet from "./Pages/Wallet";
import DetailedPage from "./Pages/DetailedPage";
import NotificationPage from "./Pages/NotificationPage";
import WatchHistory from "./Pages/WatchHistory";
import VideoPlayer from "./Pages/MoviePage/VideoPlayer";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import SuggestedOrSearchMovies from "./components/SuggestedOrSearchMovies";
import Subscriptions from "./Pages/Subscription";
import ProtectedRoute from "./utils/ProtectedRoute";
// import Profile from "./Pages/Profile";
import AboutUs from "./Pages/aboutUs";
import Feedback from "./Pages/Feedback";
import Help from "./Pages/Help";
import UserCollections from "./Pages/UserCollections";
import MoviesByGenerAndLanguage from "./Pages/MoviesByGenerAndLanguage";
import BlogHome from "./Pages/IottBlog";
import RecentReleases from "./Pages/IottBlog/RecentReleases";
import BlockDetailsPage from "./Pages/IottBlog/BlogDetailPage";
import PageNotFound from "./components/PageNotFound";
import MySubscriptions from "./Pages/Subscription/MySubscriptions";
import Check from "./Pages/Subscription/Check";
import Profile from "./Pages/Profile";
import Refund from "./Pages/RefundPage";
import ReferAndEarn from "./Pages/ReferAndEarn";
import ResetPassword from "./Pages/AuthPages/ResetPassword";

function App() {
  return (
    <div>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/refund" element={<Refund/>} />

            <Route
              exact
              path="/suggestions"
              element={<SuggestedOrSearchMovies />}
            />

            <Route
              exact
              path="/details/:movieNameParam"
              element={<DetailedPage />}
            />
            <Route
              exact
              path="/category/:gener"
              element={<MoviesByGenerAndLanguage />}
            />

            <Route exact path="/subscriptions" element={<Subscriptions />} />
            <Route element={<ProtectedRoute />}>
              <Route exact path="/wallet" element={<Wallet />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route
                exact
                path="/notifications"
                element={<NotificationPage />}
              />
              <Route
                exact
                path="/refer&earn"
                element={<ReferAndEarn />}
              />
              <Route
                exact
                path="/my_subscriptions"
                element={<MySubscriptions />}
              />
              <Route exact path="/collections" element={<UserCollections />} />
              <Route exact path="/watch_history" element={<WatchHistory />} />
              <Route exact path="/movie-player/:id" element={<VideoPlayer />} />
              <Route exact path="/feedback" element={<Feedback />} />
            </Route>

            <Route exact path="/feedback" element={<Feedback />} />
            <Route exact path="/help" element={<Help />} />
            <Route exact path="/t&c" element={<TermsAndConditions />} />
            <Route exact path="/aboutUs" element={<AboutUs />} />
            <Route exact path="/privacy&policy" element={<PrivacyPolicy />} />

            <Route exact path="/blog/:category" element={<BlogHome />} />

            <Route
              exact
              path="/blog/recentreleases"
              element={<RecentReleases />}
            />
            <Route exact path="/blog/details" element={<BlockDetailsPage />} />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
