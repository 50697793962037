import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";

import { useNavigate, useLocation } from "react-router-dom";

const AuthForcedLoginState = {
  authForcedDetails: [],
  authForcedLoginStatus: statusCodes.IDLE,
  authForcedLoginLoader: false,
};

const access_token = localStorage.getItem("token");

export const authForcedLogin = createAsyncThunk(
  "authForcedLogin/post",
  async (userLoginJSON) => {
    // let userLoginObj;
    // const userLoginJSONlength = Object.keys(userLoginJSON).length
    // // console.log(userLoginJSONlength)

    // if (userLoginJSONlength === 5) {
    //   userLoginObj = {
    //     Email: userLoginJSON.Email,
    //     Password: userLoginJSON.Password,
    //     // PhoneNo: userLoginJSON.PhoneNo,
    //     DeviceName: userLoginJSON.DeviceName,
    //   }
    // }else{
    //   userLoginObj = userLoginJSON
    // }

    // // console.log("Login Details:", userLoginJSON);

    // // console.log("Login Data:", userLoginObj)
    try {
      const userData = await axios.post(
        `${endpoints.forced_login}`,
        userLoginJSON,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      return userData.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data,
      };
      // console.log(error.response.data);
      throw error; // Throwing the error payload
    }
  }
);

const authForcedLoginReducer = createSlice({
  name: "authForcedLogin_redux",
  initialState: AuthForcedLoginState,

  extraReducers: (builder) => {
    builder
      .addCase(authForcedLogin.pending, (state, action) => {
        state.authForcedLoginStatus = statusCodes.LOADING;
        state.authForcedLoginLoader = true;
      })
      .addCase(authForcedLogin.fulfilled, (state, action) => {
        // console.log("success Login forced");
        state.authForcedDetails = action.payload;
        state.authForcedLoginStatus = statusCodes.SUCCESS;
        state.authForcedLoginLoader = false;
        localStorage.setItem("isSignIn", true);
      })
      .addCase(authForcedLogin.rejected, (state, action) => {
        // console.log("Login Failure forced");
        state.authForcedDetails = action.error.message;
        state.authForcedLoginStatus = statusCodes.ERROR;
        state.authForcedLoginLoader = false;
      });
  },
});

export default authForcedLoginReducer.reducer;
