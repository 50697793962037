import axios from "axios";
import { useEffect, useState } from "react";
import "./index.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  createTheme,
  Divider,
  Grid,
  makeStyles,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { GridOff } from "@material-ui/icons";
import Footer from "../../components/Footer";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFDA78", // Border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "green", // Border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff", // Border color when focused
          },
        },
        input: {
          "&::placeholder": {
            color: "#fff", // Placeholder color
          },
          color: "#fff",
        },
      },
    },
  },
});

const Help = () => {
  const [helpText, setHelpText] = useState("");
  const [helpStatus, setHelpStatus] = useState("");
  const [flag, setFlag] = useState(false);
  const [additionalInput, setAdditionalInput] = useState("");
  const [issue, setIssue] = useState("");

  const handleSubmit = async () => {
    console.log(additionalInput.length);
    if (!helpStatus.trim() || !additionalInput || additionalInput.length < 10) {
      setHelpText("Please fill required  * fields");
      return;
    }
    try {
      setHelpText("");
      const response = await axios.post(
        "https://api.iott.co.in/api/useroperations/userhelp",
        {
          Issue: helpStatus,
          Number: additionalInput,
        }
      );
      console.log(response.data);
      setHelpText("Your request Added Successfully");
      setAdditionalInput("");
      setHelpStatus("");
    } catch (error) {
      setHelpText("something went wrong, try again !");
      console.error("Error submitting feedback:", error);
    }
  };

  function handleMore() {
    setFlag(true);
  }

  function handleLess() {
    setFlag(false);
  }

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10 && /^[0-9]*$/.test(value)) {
      setAdditionalInput(value);
    }
  };

  return (
    <>
      <Grid container xs={12} bgcolor={"#000"}>
        <Header />
        <Grid minHeight={600} minWidth={"100%"}>
          <Box pt={3} textAlign={"center"}>
            <Typography
              fontFamily={"Quicksand"}
              // fontSize={40}
              fontWeight={700}
              color={"#FFDA78"}
              sx={{ fontSize: { xs: 30, sm: 40 } }}
            >
              Help
            </Typography>
          </Box>
          <Grid
            xs={12}
            mt={5}
            display={"flex"}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Grid
              xs={12}
              md={6}
              lg={6}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Accordion className="faq-accordian">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    fontStyle={{ fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 10, sm: 16 } }}
                  >
                    Where Can I download IOTT?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                    It's available on Android Google Play Store and Apple app
                    Store with no charges.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-accordian">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography
                    style={{ fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 10, sm: 16 } }}
                  >
                    Where can I register to I OTT?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                    You can register to I OTT in register screen with required
                    details.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-accordian">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography
                    style={{ fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 10, sm: 16 } }}
                  >
                    Where can I login to I OTT?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                    You can Login to I OTT in Login screen with required
                    registered details.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-accordian">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography
                    sx={{ fontSize: { xs: 10, sm: 16 }, fontWeight: "bold" }}
                  >
                    Does I OTT have content on regional languages?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                    IOTT offers best content on available regional languages and
                    with subtitles in some languages.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {!flag ? (
                <Box sx={{ pl: { xs: "auto" } }}>
                  <a onClick={() => handleMore()} className="faq-more-less">
                    more
                  </a>
                </Box>
              ) : (
                ""
              )}
              {flag ? (
                <Grid
                  xs={12}
                  // md={12}
                  // lg={12}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        fontStyle={{ fontWeight: "bold" }}
                        sx={{ fontSize: { xs: 10, sm: 16 } }}
                      >
                        Can I download Content videos on I OTT?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                        No, you cannot download the content videos to watch
                        offline.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 16 },
                          fontWeight: "bold",
                        }}
                      >
                        How can I recharge my I OTT Wallet?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                        Tap on the Wallet option, Add funds and by using Credit
                        card, Debit card, Internet banking and UPI are accepted.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 16 },
                          fontWeight: "bold",
                        }}
                      >
                        How many days validity for a movie unlocked in IOTT?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                        If the movie is once unlocked, it's absolutely free for
                        your entire lifetime.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 16 },
                          fontWeight: "bold",
                        }}
                      >
                        Can I install my IOTT account on new device?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                        You can, but only one login for one account. You can
                        your I OTT on your new phone with your settings, Watch
                        list and movie unlocks unchanged.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 16 },
                          fontWeight: "bold",
                        }}
                      >
                        I made my transaction, but my wallet isn't recharged?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                        We are sorry for that; it might be technical issues not
                        reflecting on I OTT. If any technical failure the amount
                        will be credited to source within 7 business days
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 16 },
                          fontWeight: "bold",
                        }}
                      >
                        Can I withdraw money from my I OTT account?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                        No, once your wallet is topped up. It is non-refundable
                        and you can use it unlock as many movies as you can.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 16 },
                          fontWeight: "bold",
                        }}
                      >
                        Issue during Register or Login on I OTT?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                        We are very sorry at the first place. Once check your
                        internet connection and Enter Correct username and
                        password. Remember only one login for one account.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 16 },
                          fontWeight: "bold",
                        }}
                      >
                        How can I change my registered mobile number?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                        You cannot update the mobile number. If you want to
                        login with your new mobile number you have to register
                        with new mobile number.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 16 },
                          fontWeight: "bold",
                        }}
                      >
                        Error while playing video?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                        Stabilize your Internet connection and Try again. If you
                        still haven't satisfied. Contact our Helpline service.
                        We will resolve as soon as possible.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 16 },
                          fontWeight: "bold",
                        }}
                      >
                        Any Hidden charges on I OTT?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>No Hidden charges at any Point.</Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="faq-accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 16 },
                          fontWeight: "bold",
                        }}
                      >
                        Can I set Personalize settings in I OTT?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: { xs: 10, sm: 16 } }}>
                        You can personalize your watch list, your name in
                        profile screen, Parental Controls and can track your
                        history.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Box sx={{ pl: { xs: "auto" } }}>
                    <a onClick={() => handleLess()} className="faq-more-less">
                      less
                    </a>
                  </Box>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "#FFDA78" }}
            />
            <Grid
              xs={12}
              md={6}
              lg={6}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box textAlign={"center"}>
                <Typography
                  fontFamily={"Quicksand"}
                  // fontSize={30}
                  sx={{ fontSize: { xs: 15, sm: 30 }, pt: { xs: 4, md: 0 } }}
                  fontWeight={700}
                  color={"#FFDA78"}
                  pb={2}
                >
                  Your Questions
                </Typography>
                <ThemeProvider theme={theme}>
                  <TextField
                    type="text"
                    placeholder="Enter your PhoneNo *"
                    value={additionalInput}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{
                      width: { xs: "100%" },
                      pb: 3,
                    }}
                    inputProps={{ maxLength: 10 }}
                  />
                </ThemeProvider>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                pb={2}
                textAlign={"center"}
              >
                <textarea
                  id="feedbackTextarea"
                  name="feedbackTextarea"
                  rows="5"
                  cols="28"
                  className="text-area"
                  placeholder="Enter Your Text *"
                  color="#fff"
                  value={helpStatus}
                  onChange={(e) => setHelpStatus(e.target.value)}
                />
              </Box>
              <Box textAlign={"center"} pt={1.5}>
                <Button
                  size="large"
                  onClick={handleSubmit}
                  sx={{
                    borderRadius: 1,
                    width: { xs: "100%", sm: "100%" },
                    height: { xs: "none" },
                    fontWeight: 700,
                    fontSize: { xs: 15, sm: 20 },
                  }}
                >
                  SUBMIT
                </Button>
                {helpText && (
                  <Box textAlign={"center"} pt={1.5}>
                    <Typography
                      fontFamily={"Quicksand"}
                      fontSize={20}
                      fontWeight={700}
                      color="#fff"
                      width={"100%"}
                    >
                      {helpText}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    </>
  );
};

export default Help;
