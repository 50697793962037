import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes"; 
import endpoints from "../../Endpoints/endpoints"; 

const AuthRegState = {
  authRegisterResponse: [],
  authRegisterStatus: statusCodes.IDLE,
  authRegisterLoader: false,
};

// {
//   headers: {
//     Authorization: `Bearer ${access_token}`,
//   },
// }
 

const access_token = localStorage.getItem("token")
// console.log(access_token)
export const authRegistration = createAsyncThunk(
  "authRegister/post",
  async (userRegisterJSON) => {
    // console.log(userRegisterJSON);
     // console.log(endpoints.Register);
    try {
      const userRegister = await axios.post(
        `${endpoints.Register}`,
        // "https://api.iott.co.in/api/user/Register",
        userRegisterJSON,
      );
      // console.log(userRegister.data)
      return userRegister.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data,
      };
      // console.log(error);
      throw error; // Throwing the error payload
    }
  }
);

const authRegisterReducer = createSlice({
  name: "authRegister_redux",
  initialState: AuthRegState,
  extraReducers: (builder) => {
    builder
      .addCase(authRegistration.pending, (state, action) => {
        state.authRegisterStatus = statusCodes.LOADING;
        state.authRegisterLoader = true;
      })
      .addCase(authRegistration.fulfilled, (state, action) => {
        state.authRegisterResponse = action.payload;
        state.authRegisterStatus = statusCodes.SUCCESS;
        state.authRegisterLoader = false;
      })
      .addCase(authRegistration.rejected, (state, action) => {
        // console.log("Register Error Redu:",  action.error)
        state.authRegisterResponse = action.error; // Accessing the customized error payload
        state.authRegisterStatus = statusCodes.ERROR;
        state.authRegisterLoader = false;
      });
  },
});

export default authRegisterReducer.reducer;
